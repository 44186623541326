<template>
  <div class="home">
    <div class="div_content5">
      <b-container class="bv-example-row" >
        <b-row >
          <b-col sm="12">
            <b-row>
              <b-col sm="6">
                <label class="text-krub text-bold text-60" style="color:#fff">FIND</label>
            <label class="text-24 text-krub-reg" style="margin-left:15px;color:#c5c178">Your Book</label>
              </b-col>
              <b-col sm="6" style="text-align:right">
                <!-- <label class="text-14 text-krub" style="margin-top:25px;color:#fff">EDITION</label><br>
                <label class="text-14 text-krub" style="color:#fff">2023</label> -->
              </b-col>
            </b-row>
            
          </b-col>
         
          <b-col sm="12" style="margin-bottom:25px">
            <b-row>
              <b-col sm="3" v-for="(produk,index) in list_produk" :key="produk.id">
                <div class="border-outline">
                  <div class="number-book">
                    <label class="text-krub">{{index+1+offset}}</label>
                  </div>
                  <img :src="'data:image/png;base64,'+produk.foto" class="image-book" style="width:100%" @click="openImage(produk.foto)">
                </div>
                <b-button class="button-biru text-krub" @click="gotoBeli(produk.link_tokped,produk.link_shopee)">BELI YUK</b-button>
              </b-col>
              
              <b-col sm="12" style="text-align:center;margin-top:45px">
                  <div style="display:flex">
                    <!-- <label class="text-krub text-14" style="color:#fff;margin-top:25px;margin-right:25px;cursor:pointer">MORE PRODUCT</label> -->
                    <div class="button-next" @click="gotoPrev()">
                        <img src="../assets/arrow-left.png" style="width:50px;cursor:pointer">
                    </div>
                    <div class="currentNumber">
                        <label class="text-krub text-14" style="color:#fff">{{curentPage}}</label>
                    </div>
                    <div class="button-next" @click="gotoNext()">
                        <img src="../assets/arrow-right.png" style="width:50px;cursor:pointer">
                    </div>
                  </div>
              </b-col>
            </b-row>
          </b-col>
          <!-- <b-col sm="12" style="margin-bottom:45px">
            <b-button variant="danger">Daftar Sekarang</b-button>
          </b-col> -->
        </b-row>
      </b-container>
    </div>
    
    <div class="div_content5">
      <b-container class="bv-example-row" >
        <b-row >
          <b-col sm="12" style="text-align:center;margin-bottom:85px">
            <label class="text-krub text-bold text-60" style="color:#fff;margin-top:100px">What</label><br>
            <label class="text-24 text-krub-reg" style="margin-left:15px;color:#c5c178">users think .</label>
          </b-col>
          <b-col sm="12">
            <b-row>
              <b-col sm="4" v-for="(testi) in list_testimoni" :key="testi.id">
                <div style="width:100%;text-align:center">
                  <img src="../assets/user-img.png" style="width:120px" v-show="testi.foto == '' || testi.foto == null">
                  <div>
                    <span class="fa fa-star checked" v-if="testi.rating >= 1"></span>
                    <span class="fa fa-star" v-if="testi.rating < 1"></span>
                    <span class="fa fa-star checked" v-if="testi.rating >= 2"></span>
                    <span class="fa fa-star" v-if="testi.rating < 2"></span>
                    <span class="fa fa-star checked" v-if="testi.rating >= 3"></span>
                    <span class="fa fa-star" v-if="testi.rating < 3"></span>
                    <span class="fa fa-star checked" v-if="testi.rating >= 4"></span>
                    <span class="fa fa-star" v-if="testi.rating < 4"></span>
                    <span class="fa fa-star checked" v-if="testi.rating >= 5"></span>
                    <span class="fa fa-star" v-if="testi.rating < 5"></span>
                  </div>
                </div>
                <div style="text-align:center;margin-top:15px">
                  <label class="text-krub" style="color:#aeaeae">{{testi.name}}</label>
                </div>
                <div style="text-align:center;margin-top:15px">
                  <label class="text-krub" style="color:#a197a6">"{{testi.comment}}"</label>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <b-modal id="modal-olshop" size="sm" centered >
        <div class="w-100" style="text-align:center">
            <a :href="link_shopee" target="_blank">
                <img src="../assets/shopee-icon.png" class="pointer-select" style="width:80px;margin-right:15px" >
            </a>
            <a :href="link_tokped" target="_blank">
                <img src="../assets/tokped-icon.png" class="pointer-select" style="width:80px;margin-right:15px" >
            </a>
        </div>
        <template #modal-footer>
            <div class="w-100">
                <b-button @click="closeModal()" class="button-biru text-krub">Tutup</b-button>
            </div>
        </template>
    </b-modal>
    </div>
    <Footer />
    <b-modal id="modal-image" title="BootstrapVue" hide-footer hide-header>
      <img :src="'data:image/png;base64,'+zoomImage" class="image-book" style="width:100%">
    </b-modal>
  </div>
  
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Footer from '@/views/Footer.vue'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

export default {
  name: 'Home',
  components: {
    Footer
    // HelloWorld
  },
  data() {
      return {
        list_produk: [],
        list_testimoni:[],
        offset:0,
        perpage:12,
        offsetT:0,
        perpageT:3,
        curentPage:1,
        link_tokped:'',
        link_shopee:'',
        zoomImage:''
      }
    },
    mounted(){
        const vm = this;
        const req_post = {
            perpage:this.perpage,
            offset:this.offset
        }
        Vue.axios({
            method: 'post',
            url: this.$urlApi+'list_catalog',
            headers: { 'content-type': 'application/json' },
            data: JSON.stringify(req_post),
        }).then(function (response) {
            var result = response.data;
            console.log(result);
            // if(result.error != true)
            if(result.status == 'Success')
            {
                
                vm.list_produk = result.produk;
            }
        });
        const req_testi = {
            perpage:this.perpageT,
            offset:this.offsetT
        }
        Vue.axios({
            method: 'post',
            url: this.$urlApi+'list_testimoni',
            headers: { 'content-type': 'application/json' },
            data: JSON.stringify(req_testi),
        }).then(function (response) {
            var result = response.data;
            console.log(result);
            // if(result.error != true)
            if(result.status == 'Success')
            {
                
                vm.list_testimoni = result.testimoni;
            }
        });
    },
    methods: {
      openImage(value){
        this.zoomImage = value;
        this.$bvModal.show('modal-image')
      },
      gotoBeli(link_tokped,link_shopee){
        this.$bvModal.show('modal-olshop')
        this.link_tokped = link_tokped;
        this.link_shopee = link_shopee;
      },
      closeModal()
      {
        this.$bvModal.hide('modal-olshop')
      },
      gotoNext(){
        // var page = this.curentPage+1;
        var new_offset = this.offset+this.perpage;
        console.log(new_offset)
        const vm = this;
        const req_post = {
            perpage:this.perpage,
            offset:new_offset
        }
        Vue.axios({
            method: 'post',
            url: this.$urlApi+'list_catalog',
            headers: { 'content-type': 'application/json' },
            data: JSON.stringify(req_post),
        }).then(function (response) {
            var result = response.data;
            console.log(result);
            // if(result.error != true)
            if(result.status == 'Success')
            {
                if(result.produk.length > 0)
                {
                    vm.list_produk = result.produk;
                    vm.curentPage++;
                    vm.offset = new_offset;
                }
                // else
                // {
                //     vm.list_produk = result.produk;
                // }
            }
        });
      },
      gotoPrev(){
        if(this.curentPage != 1)
        {
            var new_offset = this.offset-this.perpage;
            console.log(new_offset)
            const vm = this;
            const req_post = {
                perpage:this.perpage,
                offset:new_offset
            }
            Vue.axios({
                method: 'post',
                url: this.$urlApi+'list_catalog',
                headers: { 'content-type': 'application/json' },
                data: JSON.stringify(req_post),
            }).then(function (response) {
                var result = response.data;
                console.log(result);
                // if(result.error != true)
                if(result.status == 'Success')
                {
                    if(result.produk.length > 0)
                    {
                        vm.list_produk = result.produk;
                        vm.curentPage--;
                        vm.offset = new_offset;
                    }
                    // else
                    // {
                    //     vm.list_produk = result.produk;
                    // }
                }
            });
        }
      }
    }
}
</script>
<style>
@font-face {
   font-family: myFirstFont;
   src: url("../assets/font/BStyle_B.ttf");
}
@font-face {
   font-family: Montserrat;
   src: url("../assets/font/Montserrat-VariableFont_wght.ttf");
}
@font-face {
   font-family: Oswald;
   src: url("../assets/font/Oswald-VariableFont_wght.ttf");
}
@font-face {
   font-family: Krub;
   src: url("../assets/font/Krub-Bold.ttf");
}
@font-face {
   font-family: KrubReg;
   src: url("../assets/font/Krub-Regular.ttf");
}
.button-next
{
    padding-top:10px;
    padding-bottom:10px;
    padding-right:20px;
    padding-left:20px;
    border:1px solid #fff;
    cursor: pointer;
}
.currentNumber
{
    border:1px solid #fff;
    padding-top:10px;
    padding-bottom:10px;
    padding-right:20px;
    padding-left:20px;
}
.pointer-select
{
  cursor: pointer;
}
.arrow-vertical-left
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(270deg);
  top: 40%;
  position: absolute;
}
.arrow-vertical-right
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(90deg);
  top: 55%;
  position: absolute;
  right: 0;
}
.vertical-left
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 50%;
  position: absolute;
}
.vertical-left2
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 50%;
  position: absolute;
}
.vertical-right
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 30%;
  right: 0;
  position: absolute;
}
.vertical-right2
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 40%;
  right: 0;
  position: absolute;
}
.form-pertanyaan
{
  margin-top: 25px;
  background-color: transparent !important;
  border: 2px solid #9172d1 !important;
  border-radius: 0 !important;
  color:#fff !important;
}
.form-newsletter
{
  margin-top: 25px;
  background-color: transparent !important;
  border: 2px solid #fff !important;
  border-radius: 0 !important;
  color:#000 !important;
}
/* .person3-img
{
  width: 600px;
  position: absolute;
} */
.pertantaan-div
{
  display: block;
  position: relative;
  height: 400px;
}
.pertantaan-div img
{
  width: 95%;
  position: absolute;
}
.image-person1 img
{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.oval-bg1
{
  position: absolute;
}
.oval-bg2
{
  margin-top: -200px;
  position: absolute;
  right: 0;
}
.button-biru
{
  background-color: #8eb0ff !important;
  font-family: Krub !important;
  font-size: 12px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
}
.button-biru-left
{
  background-color: #8eb0ff !important;
  font-family: Krub !important;
  font-size: 12px !important;
}
.border-outline
{
  border:1px solid #fff;
  padding:25px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.number-book
{
  position: absolute;
  background-color:#fff ;
  padding:10px;
  margin-top: -25px;
  margin-left: -25px;
}
.border-outline img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.text-h{
  font-family: myFirstFont;
  font-size: 40px;
}
.text-krub{
  font-family: Krub;
}
.text-krub-reg{
  font-family: KrubReg;
}
.text-oswald{
  font-family: Oswald;
}
.text-monts{
  font-family: Montserrat;
}
.text-42{
  font-size: 42px;
}
.text-60{
  font-size: 60px;
}
.text-14{
  font-size: 14px;
}
.text-24{
  font-size: 24px;
}
.text-bold
{
  font-weight: bolder;
}
.text-m{
  font-size: 20px;
}
.div_content1
{
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #000;
    color: #fff;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)); */

}
.div_content2
{
    padding-top: 120px;
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    position: relative;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content3
{
    padding-top: 80px;
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content4
{
    padding-top: 150px;
    padding-bottom: 50px;
    background-color: #7b368b;
    position: relative;
    color: #000;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content5
{
    /* padding-top: 50px; */
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content6
{
    /* padding-top: 50px; */
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    /* height: 850px; */
}
.div_content7
{
    /* padding-top: 50px; */
    padding-bottom: 50px;
    background-color: #000;
    color: #fff;
    /* height: 850px; */
}
.image-oval4
{
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 100%;
}
@media only screen and (max-width: 600px) {
  .image-oval4
  {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
  }
  .vertical-left2
  {
    display: none;
  }
  .vertical-right2
  {
    display: none;
  }
}
</style>